@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300&display=swap");

* {
  transition: all 0.3s ease-in-out;
  font-family: "Be Vietnam Pro", sans-serif;
}

html {
  font-size: 62.5%;
  user-select: none;
}

body {
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  html {
    font-size: 50%;
  }

  body {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  html {
    font-size: 57.5%;
  }

  body {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  html {
    font-size: 57.5%;
  }

  body {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  html {
    font-size: 57.5%;
  }

  body {
    display: flex;
    justify-content: center;
  }
}
